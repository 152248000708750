import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import NewsGrid from "../components/NewsGrid";
import ReactLightboxV2 from "../components/ReactLightboxV2";
import TestimonialSliderDark from "../components/TestimonialSliderDark";


import {
  newsFeaturedArticleContent,
  newsGalleryHeader,
  latestNewsHeroDetails,
  feedback,
} from "../constants";

const News = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: allFile(filter: { name: { in: "hero-news" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 3.92
            )
          }
        }
      }
      medivet: allFile(
        filter: {
          name: {
            in: [
              "07-medivet-project"
              "09-medivet-project"
              "12-medivet-project"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      #longcrossStudiosProjectImages: allFile(
      #  filter: {
      #   name: {
      #      in: [
      #        "dhl-gallery-01"
      #        "dhl-gallery-02"
      #        "dhl-gallery-03"
      #      ]
      #    }
      #  }
      #  sort: { fields: name, order: ASC }
      #) {
      #  nodes {
      #    name
      #    childImageSharp {
      #      gatsbyImageData(
      #        formats: AUTO
      #        layout: CONSTRAINED
      #        placeholder: BLURRED
      #      )
      #    }
      #  }
      #}
      galleryImages: allFile(
        filter: {
          name: {
            in: [
              "01-slough-in-bloom-2023-overall-winner"
              "slough-in-bloom-01"
              "slough-in-bloom-02"
              "slough-in-bloom-03"
              "slough-in-bloom-04"
              "slough-in-bloom-05"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);

  //console.log("Data: ", data);

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify([{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Burnham Junior FC",
          "item": "https://intact-electrical.co.uk/articles/burnhan-under-15s-football-team"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "EIRC for landlords",
          "item": "https://intact-electrical.co.uk/articles/eicr-for-landlords"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "Slough In Bloom - 2023",
          "item": "https://intact-electrical.co.uk/articles/slough-in-bloom"
        },{
          "@type": "ListItem",
          "position": 4,
          "name": "Featured project - Medivet Watford",
          "item": "https://intact-electrical.co.uk/projects/medivet-watford"
        }]
      }])}
    </script>
  )

  const featuredContentImages = data.medivet.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = latestNewsHeroDetails;

  return (
    <>
      <Seo       
        title="Latest news"
        description="Intact Electrical, community, articles & events, Events gallery, Slough In Bloom 2023, Burnham FC Junior H&S Pepper"
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={newsFeaturedArticleContent}
        featuredContentImages={featuredContentImages}
      />
      <NewsGrid />

      <ReactLightboxV2
        images={data.galleryImages.nodes}
        galleryContent={newsGalleryHeader}
        location="services-page"
      />

      <TestimonialSliderDark feedback={feedback} />
    </>
  );
};

export default News;
