import React, { useState } from "react"
import { useForm } from "@formspree/react"
import validate from "../constants/validate-contact"

import { StaticImage } from "gatsby-plugin-image";
import styles from "../styles/style.js";
import Headings from "./Headings.js";
import { Link } from "gatsby";

const NewsGrid = () => {
  const [state, handleSubmit] = useForm(process.env.GATSBY_NEWSLETTER_FORM)
  const [values, setValues] = useState({
    email: "",
  })
  const [errors, setErrors] = useState({
    email: "",
  })
  const [email, setEmail] = useState({
    email: "",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setValues({
      ...values,
      [name]: value,
    })
    //console.log("values: ", values)
  }
  const manageSubmit = e => {
    e.preventDefault()

    // other logic or error checking logic here
    setErrors(validate(values))
    //console.log("errors: ", errors)
    //console.log("values: ", values)

    if (values.email.length > 0) {
      //console.log("values in if statement: ", values)
      setEmail(values.email)
      handleSubmit(e)
      resetForm()
      //console.log("Submitted successfully")
    }
  }

  const resetForm = () => {
    values.email = ""
  }

  return (
    <div
      id="latest-news"
      className={`bg-[#ffffff] ${styles.paddingX} ${styles.flexCenter} mt-4`}
    >
      <div className={`${styles.boxWidth}`}>
        <div className="mb-2">
          <Headings
            titleTop="Latest update"
            titleBottom="from the community"
            description="Burnham Junior FC is an established accredited local junior football club, catering for players aged from U7 to U18. developing the skills of young players from the local community."
          />
        </div>

        <main className="">
          <div className="block lg:flex lg:space-x-2 lg:p-0 mb-6 mt-6 ">
            <Link             
              to="/articles/burnham-junior-fc"
              alt="burnhan under 15s football team"
              aria-label="burnhan under 15s football team"
              className="mb-4 md:mb-0 w-full  relative rounded inline-block"
              style={{ height: "24em" }}
            >
              <div
                className="absolute left-0 bottom-0 w-full h-full z-10"
                style={{
                  backgroundImage:
                    "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                }}
              ></div>
              <StaticImage
                src="../images/articles/burnham-football-club/team-away-kit.png"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="this is alt"
                //transformOptions={{ fit: "cover", cropFocus: "attention" }}
                className="absolute left-0 top-0 w-full h-full rounded z-0 object-cover"
              />
              <div className="p-6 absolute bottom-0 left-0 z-20">
                <span className="px-4 py-1 bg-black text-gray-200 inline-flex items-center justify-center mb-2">
                  Partnerships
                </span>
                <h2 className={` mb-4 ${styles.headingFeaturedArticle}`}>
                  Burnham Junior FC under 15 team.
                </h2>
                <div className="flex mt-3">
                  <StaticImage
                    src="../images/profile-images/richard-profile-picture-v4.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                    //aspectRatio={1.0}
                  />
                  <div>
                    <p className="font-semibold text-gray-200 text-sm">
                      {" "}
                      Richard Barber{" "}
                    </p>
                    <p className="font-semibold text-quinary text-xs">
                      {" "}
                      31 September 2023
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div id="articles-popular-topics">
            <Headings
              titleTop="Popular topics"
              titleBottom="articles & events"
              description="A one-stop-shop to catch up with what we’ve been up to. Read more about our clients, partnerships and events. Everything from our professional business to our community contribution."
            />
          </div>

          <div className="block lg:flex lg:space-x-2 lg:p-0 mt-6">
            {/* <!-- post cards container --> */}
            <div className="w-full lg:w-2/3">
              {/* <!-- post cards --> */}
              <Link               
                to="/projects/biffa-workshop"
                alt="Ladbrokes woking project"
                aria-label="Ladbrokes woking project"
                className="block rounded w-full lg:flex mb-10"
                >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/projects/biffa/01-biffa-work-shop.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        BIFFA - no time to waste
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Intact Electrical were hired to fit out Biffa's brand-new workshop. We provided professiional electrical lighting installation services in addition to power, data and air conditioning feeds.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/richard-profile-picture-v4.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Richard Barber{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/projects/herschel-grammar"
                alt="Herschel Grammar School Contract"
                aria-label="Herschel Grammar School Contract"
                className="block rounded w-full lg:flex mb-10"
              >
                <div>
                  <StaticImage
                    src="../images/contracts/herschel-grammar/herschel-grammar-accent-lights.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                </div>
                <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                  <div>
                    <h2
                      className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                    >
                     Herschel Grammar - school contract
                    </h2>
                    <p className="font-magistral font-normal text-quaternary w-full">
                    Herschel Grammar School is a co-educational grammar school with academy status, located in Slough, Berkshire. The school has around 900 pupils.
                    </p>
                  </div>
                  <div className="flex mt-3">
                    <StaticImage
                      src="../images/profile-images/richard-profile-picture-v4.jpg"
                      placeholder="blurred"
                      formats={["auto", "webp", "avif"]}
                      alt="meet the team"
                      className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                    />
                    <div className="mb-2">
                      <p className="font-semibold text-gray-700 text-sm capitalize">
                        {" "}
                        Richard Barber{" "}
                      </p>
                      <p className="text-quinary font-semibold text-xs">
                        {" "}
                        05 November 2023{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/articles/racer-at-heart"
                alt="Gavin Little racer at heart"
                aria-label="Gavin Little racer at heart"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex ">
                  <StaticImage
                    src="../images/articles/gavin-the-racer/08-gavin-little-live-racing.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        Gavin Little - racer at heart
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      It was a good few years ago, but I remember it like it was yesterday. I was sitting on the bank at Brands Hatch with my friends watching a motorbike race start.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/gavin-profile-picture-v3.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Gavin Little{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          05 November 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/projects/pioneer-trust-school-contract"
                alt="Pioneer Trust School Contract"
                aria-label="Pioneer Trust School Contract"
                className="block rounded w-full lg:flex mb-10"
              >
                <div>
                  <StaticImage
                    src="../images/contracts/pioneer-trust/01-pioneer-trust-electrical-work.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                </div>
                <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                  <div>
                    <h2
                      className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                    >
                     Pioneer Trust - school contract
                    </h2>
                    <p className="font-magistral font-normal text-quaternary w-full">
                      Pioneer Education Trust incorporates Desborough College, Foxborough Primary School, Trevelyan Middle School and Upton Court Grammar School.
                    </p>
                  </div>
                  <div className="flex mt-3">
                    <StaticImage
                      src="../images/profile-images/richard-profile-picture-v4.jpg"
                      placeholder="blurred"
                      formats={["auto", "webp", "avif"]}
                      alt="meet the team"
                      className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                    />
                    <div className="mb-2">
                      <p className="font-semibold text-gray-700 text-sm capitalize">
                        {" "}
                        Richard Barber{" "}
                      </p>
                      <p className="text-quinary font-semibold text-xs">
                        {" "}
                        05 November 2023{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/articles/football-coach"
                alt="Richard Barber - Football coach"
                aria-label="Richard Barber - Football coach"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex ">
                  <StaticImage
                    src="../images/articles/burnham-football-club/01-the-coach.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        Richard Barber - football coach
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Wow what a journey! I'm still not quite sure how it happened. It started with me as a proud parent watching his kids playing for local youth teams at Burnham Junior Football Club.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/richard-profile-picture-v4.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Richard Barber{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          05 November 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/articles/slough-in-bloom"
                alt="Slough in Bloom - 2023"
                aria-label="Slough in bloom - 2023"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex ">
                  <StaticImage
                    src="../images/partners/slough-in-bloom/slough-in-bloom-01.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        Slough in Bloom - 2023
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Intact Electrical were proud 2023 sponsors of the local  Slough in Bloom Competition that was launched for its 29th year. 
                      This year’s results were announced on 16th September 2023.  Congratulations to Simon Richardson, the well-deserved overall winner.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/richard-profile-picture-v4.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Richard Barber{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/projects/ladbrokes-woking"
                alt="Ladbrokes woking project"
                aria-label="Ladbrokes woking project"
                className="block rounded w-full lg:flex mb-10"
              >
                <div>
                  <StaticImage
                    src="../images/projects/ladbrokes/06-ladbrokes-project.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                </div>
                <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                  <div>
                    <h2
                      className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                    >
                      Ladbrokes - a safe bet
                    </h2>
                    <p className="font-magistral font-normal text-quaternary w-full">
                      Intact Electrical were chosen as the preferred and trusted
                      electrical contractor by Woodhouse Workspace as they
                      strived to transform Ladbrokes new Woking office space Cat
                      B fit out to the client’s requirements.
                    </p>
                  </div>
                  <div className="flex mt-3">
                    <StaticImage
                      src="../images/profile-images/gavin-profile-picture-v3.jpg"
                      placeholder="blurred"
                      formats={["auto", "webp", "avif"]}
                      alt="meet the team"
                      className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                    />
                    <div className="mb-2">
                      <p className="font-semibold text-gray-700 text-sm capitalize">
                        {" "}
                        Gavin Little{" "}
                      </p>
                      <p className="text-quinary font-semibold text-xs">
                        {" "}
                        28 September 2023{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              
              <Link               
                to="/projects/domestic-installations"
                alt="Domestic electrical installation project"
                aria-label="Domestic electrical installation project"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/adverts/01-local-domestic-installations.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        <span>No visible wire policy</span>
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Domestic electrical installations for a local customer with a no visible wire policy in their home. We were happy to advise and provide a solution, completing the work professionally and safely.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/gavin-profile-picture-v3.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Gavin Little{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              
              <Link               
                to="/projects/dhl-reading"
                alt="DHL Reading project"
                aria-label="DHL Reading project"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/projects/dhl/dhl-gallery-16.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        DHL - simply delivered
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                        Intact Electrical were chosen as the preferred and
                        trusted electrical contractor by Woodhouse Workspace as
                        they strived to deliver DHL’s new Reading distribution
                        hub Cat B fit out to the client’s requirements.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/gavin-profile-picture-v3.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Gavin Little{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
                            
              <Link               
                to="/projects/harvey-nichols"
                alt="Harvey Nichols project"
                aria-label="Harvey Nichols project"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/projects/harvey-nichols/02-harvey-nicholes-chiller-upgrade.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        Harvey Nichols - gourmet service
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Intact Electrical were chosen as the preferred service provider for the chiller upgrade project at their flagship Knightsbridge store, liaising with National Grid personnel for onsite connectivity.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/richard-profile-picture-v4.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Richard Barber{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/articles/scr-racing"
                alt="Intact Electrical - Sam Cox Racing team sponsorship"
                aria-label="Intact Electrical - Sam Cox Racing team sponsorship"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/partners/src-racing/24-src-racing.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        SCR 31 BSB sponsorship
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      With some great opportunities via both national TV and social media coverage, it was a perfect fit for us as a growing company. It was an added bonus for me to have our company involved in a sport I have personal experience in and a lasting passion for.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/gavin-profile-picture-v3.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Gavin Little{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              
              <Link               
                to="/articles/hs-pepper-co"
                alt="H&S Pepper Co local business project"
                aria-label="H&S Pepper Co local business project"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/naga-product.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        <span>H&S Pepper Co - homegrown</span>
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Intact Electrical will be supporting H&S Pepper Co at local events and trade fairs providing portable power solutions for lighting, refrigeration and entertainment for their stalls, using the opportunity to do some of our own marketing on site.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/richard-profile-picture-v4.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Richard Barber{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/articles/eicr-for-landlords"
                alt="EICR for landlords article"
                aria-label="EICR for landlords article"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/eicr-pat-services.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        <span>EICR for landlords</span>
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Private landlords in England are required to have the electrical installation in their rental properties checked by a qualified electrician to ensure that they are safe, obtaining an electrical installation condition report
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/richard-profile-picture-v4.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Richard Barber{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/articles/burnham-fc"
                alt="Intact electrical sponsors burnham fc"
                aria-label="Intact electrical sponsors burnham fc"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/articles/burnham-football-club/team-goal-celebration.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        Burnham FC sponsorship
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Since we started the company I'd thought about how it would feel having Intact Electrical on my local team's shirts. Having experienced it at youth level, the idea of it being on the local men's first team shirts was even more appealing.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/richard-profile-picture-v4.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Richard Barber{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link               
                to="/projects/the-hub-farnborough"
                alt="The Hub Farnborough project"
                aria-label="The Hub Farnborough project"
                className="block rounded w-full lg:flex mb-10"
              >
                <div className="rounded w-full lg:flex">
                  <StaticImage
                    src="../images/projects/the-hub-farnbrough/250.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <h2
                        className={`lg:mt-[0px] mt-4 ${styles.headingArticleList}`}
                      >
                        The Hub - flying high
                      </h2>
                      <p className="font-magistral font-normal text-quaternary w-full">
                      Intact Electrical were chosen as the preferred and trusted electrical installation experts for The Hub’s internal and external commercial office lighting refurbishments for there brand new contemporary office accommodation.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/gavin-profile-picture-v3.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div className="mb-2">
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Gavin Little{" "}
                        </p>
                        <p className="text-quinary font-semibold text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              

              {/* <a className="block rounded w-full lg:flex mb-10" href="/news">
                <div className="rounded w-full lg:flex mb-10">
                  <StaticImage
                    src="../images/biffa-hero-v1.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="meet the team"
                    className="h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75 rounded"
                  />
                  <div className="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                    <div>
                      <div className="mt-3 md:mt-0 text-gray-700 font-bold text-2xl mb-2">
                        BIFFA - New Office Commercial project
                      </div>
                      <p className="text-quaternary text-sm">
                        Mauris porttitor, velit at tempus vulputate, odio turpis
                        facilisis dui, vitae eleifend odio ipsum at odio.
                        Phasellus luctus scelerisque felis eget suscipit.
                      </p>
                    </div>
                    <div className="flex mt-3">
                      <StaticImage
                        src="../images/profile-images/gavin-profile-picture-v3.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-10 w-10 rounded-full mr-2 object-cover shadow-lg"
                      />
                      <div>
                        <p className="font-semibold text-gray-700 text-sm capitalize">
                          {" "}
                          Gavin Little{" "}
                        </p>
                        <p className="text-gray-600 text-xs">
                          {" "}
                          28 September 2023{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a> */}
            </div>

            {/*  <!-- right sidebar --> */}
            <div className="w-full lg:w-1/3">
              {/* <!-- topics --> */}

              {/* < !-- divider --> */}
              <div className="border border-dotted"></div>

              {/* <!-- divider --> */}
              <div className="border border-dotted"></div>
              <div className="mt-2">
                <h2 className={`uppercase ${styles.headingPopularTopics}`}>
                  Categories
                </h2>
                <ul>
                  <li className="px-1 py-2 border-b border-t border-white hover:border-gray-200 transition duration-300">
                    <a
                      href="/"
                      className="flex items-center text-gray-600 cursor-pointer"
                    >
                      <span className="inline-block h-4 w-4 bg-quinary  mr-3"></span>
                      Projects
                      <span className="text-gray-500 ml-auto">6</span>
                      <i className="text-gray-500 bx bx-right-arrow-alt ml-1"></i>
                    </a>
                  </li>                  
                  <li className="px-1 py-2 border-b border-t border-white hover:border-gray-200 transition duration-300">
                    <a
                      href="/"
                      className="flex items-center text-gray-600 cursor-pointer"
                    >
                      <span className="inline-block h-4 w-4 bg-[#a7a4a4]  mr-3"></span>
                      Partnerships
                      <span className="text-gray-500 ml-auto">2</span>
                      <i className="text-gray-500 bx bx-right-arrow-alt ml-1"></i>
                    </a>
                  </li>
                  <li className="px-1 py-2 border-b border-t border-white hover:border-gray-200 transition duration-300">
                    <a
                      href="/"
                      className="flex items-center text-gray-600 cursor-pointer"
                    >
                      <span className="inline-block h-4 w-4 bg-[#ffd500] mr-3"></span>
                      Events
                      <span className="text-gray-500 ml-auto">1</span>
                      <i className="text-gray-500 bx bx-right-arrow-alt ml-1"></i>
                    </a>
                  </li>
                  <li className="px-1 py-2 border-b border-t border-white hover:border-gray-200 transition duration-300">
                    <a
                      href="/"
                      className="flex items-center text-gray-600 cursor-pointer"
                    >
                      <span className="inline-block h-4 w-4 bg-quinary  mr-3"></span>
                      Contracts
                      <span className="text-gray-500 ml-auto">2</span>
                      <i className="text-gray-500 bx bx-right-arrow-alt ml-1"></i>
                    </a>
                  </li>
                  <li className="px-1 py-2 border-b border-t border-white hover:border-gray-200 transition duration-300">
                    <a
                      href="/"
                      className="flex items-center text-gray-600 cursor-pointer"
                    >
                      <span className="inline-block h-4 w-4 bg-[#a7a4a4] mr-3"></span>
                      Articles
                      <span className="text-gray-500 ml-auto">1</span>
                      <i className="text-gray-500 bx bx-right-arrow-alt ml-1"></i>
                    </a>
                  </li>
                </ul>
              </div>

              {/* <!-- divider --> */}
              <div className="border border-dotted"></div>

              {/* <!-- subscribe --> */}
              <div className="mb-6">
                <h2 className={`uppercase ${styles.headingPopularTopics}`}>
                  Subscribe
                </h2>
                <p className="text-gray-600  py-2 ">
                  Subscribe to our newsletter. We deliver the most useful
                  electrical related articles to your inbox
                </p>
                {/* <input
                  placeholder="your email address"
                  className="text-gray-700 bg-gray-100 rounded-t hover:outline-none p-2 w-full mt-4 border"
                />
                <button className="px-4 py-2 bg-dark-grey-radial-gradient text-[#ffffff] rounded-b w-full capitalize tracking-wide hover:text-quinary">
                  Subscribe
                </button> */}
                <form onSubmit={manageSubmit} noValidate>
                  <div className="uk-grid-small" uk-grid="true">
                    <div className="uk-width-expand@s uk-first-column uk-margin-small-bottom">
                    <input
                    id="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Enter your email..."
                    className="text-body-color border-[f0f0f0] focus:border-[#92C2DD] focus:ring-1 focus:ring-[#92C2DD] w-full rounded border py-3 px-[14px] text-base outline-none focus-visible:shadow-none mt-2 mb-2"
                  />
                  {errors.email && (
                    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-2" role="alert">                     
                      <span class="block sm:inline">{errors.email}</span>                      
                    </div>
                  )}
                    </div>
                    <div className="uk-width-1-1 uk-width-auto@s">
                      <button
                        type="submit"
                        className={`py-2 px-4 font-medium text-[18px] bg-dark-grey-radial-gradient text-[#ffffff] rounded-[10px] outline-none ${styles} hover:text-quinary w-full`}
                        style={{ backgroundColor: "#617F7F", color: "#FFFFFF" }}
                        disabled={state.submitting}
                      >
                    Submit your message
                  </button>
                    </div>
                  </div>
                  {/* state.succeeded && (
                    <div className="uk-alert-success" uk-alert="true">
                      <a href="/" className="uk-alert-close" uk-close="true">
                        {null}
                      </a>
                      <p>
                        Thank you {email}, your message has been sent successfully,
                        and you will added to our Newsletter mailing list. You can{" "}
                        <a href="mailto:unsubscribe@savethestreetpooches.org?subject=Save The Street Pooches Sri Lanka - unsubscribe from Newsletter">
                          unsubscribe
                        </a>{" "}
                        at any time. and sending the email to remove.
                      </p>
                    </div>
                  ) */}
                  {state.succeeded && (                   
                    <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mt-4" role="alert">
                    <div class="flex">
                      <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 28.32 28.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                      <div>
                        <p class="font-bold">Thank you for subscribing</p>
                        <p class="text-sm">You will added to our newsletter mailing list. You can{" "}
                        <a href="mailto:unsubscribe@intact-electrical.co.uk?subject=Intact Electrical - please unsubscribe me from the newsletter">
                          unsubscribe
                        </a>{" "}                        
                        at any time, by emailing us at unsubscribe@intact-electrical.co.uk.</p>
                      </div>
                    </div>
                  </div>
                  )}
                </form>
              </div>

              {/* < !-- divider --> */}
              <div className="border border-dotted mt-4"></div>

              {/* <!-- divider --> */}
              <div className="border border-dotted"></div>

              <div className="w-full text-center"> 
                <iframe 
                  src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fintactelectrics%2Fvideos%2F322737840469047%2F&show_text=false&width=269&t=0" 
                  //width="369"
                  //height="746"
                  height="576" 
                  title="Will Mellor video testimonial"
                  style={{border:"none", width: "100%", overflow:"hidden"}}
                  //scrolling="no" 
                  //frameBorder="0" 
                  allowFullScreen={true} 
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                  className="flex flex-wrap items-center rounded bg-white shadow-md overflow-hidden mt-6 mb-6 hover:shadow-lg transform transition hover:-translate-y-1"
                >
                </iframe>
              </div>

              {/* < !-- divider --> */}
              <div className="border border-dotted"></div>              

              <div className="">
                <Link 
                  to="/join-us" 
                  alt="Join us"
                  aria-label="Join us" 
                  className="text-sm"
                >                  
                  <div className="flex flex-wrap items-center rounded bg-white shadow-md overflow-hidden mt-6 mb-6 hover:shadow-lg transform transition hover:-translate-y-1">
                    <div>
                      <StaticImage
                        src="../images/bfc-sponsorship.jpg"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-42 w-full flex-none bg-cover text-center overflow-hidden opacity-75 rounded-t-lg"
                        aspectRatio={1.99}
                      />
                    </div>

                    <div className="px-4 py-4">
                      <h2 className={`${styles.headingNewsAdvert} mb-2`}>
                        <span
                          className={`text-gray-700 ${styles.headingNewsAdvert}`}
                        >
                          Join us
                        </span>
                        <br />
                        <span
                          className={`text-dimGrey ${styles.headingNewsAdvert}`}
                        >
                          let's collaborate
                        </span>
                      </h2>

                      <p className="text-base text-gray-600">
                        Intact Electrical are proud of our reputation for
                        excellence, based on our satisfied clients whose
                        referrals have enabled us to grow our business into what
                        it is today.
                      </p>
                      <p className="text-base text-gray-600 mt-4">
                        We take a holistic view on what is important, beyond
                        just electricals, and are consciously expanding our
                        network to collaborate with other likeminded
                        organisations.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>

              {/* < !-- divider --> */}
              <div className="border border-dotted"></div>

              <div className="w-full text-center"> 
                <iframe 
                  src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fintactelectrics%2Fvideos%2F361053209674530%2F&show_text=false&width=348&t=0" 
                  //width="348" 
                  height="576"
                  title="Gavin Little video testimonial"
                  style={{border:"none", width: "100%", overflow:"hidden"}}
                  //scrolling="no" 
                  //frameBorder="0" 
                  allowFullScreen={true} 
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                  className="flex flex-wrap items-center rounded bg-white shadow-md overflow-hidden mt-6 mb-6 hover:shadow-lg transform transition hover:-translate-y-1"
                >
                </iframe>
              </div>

               {/* < !-- divider --> */}
              {/*  <div className="border border-dotted"></div> */}

             {/*  <div className="">
              <Link 
                  to="/careers" 
                  alt="Careers with intact electrical"
                  aria-label="Careers with intact electrical" 
                  className="text-sm"
                >  
                  <div className="flex flex-wrap items-center rounded bg-white shadow-md overflow-hidden mt-6 mb-6 hover:shadow-lg transform transition hover:-translate-y-1">
                    <div>
                      <StaticImage
                        src="../images/hero-brown-books-18th-edition.png"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="meet the team"
                        className="h-42 w-full flex-none bg-cover text-center overflow-hidden opacity-75 rounded-t-lg"
                        aspectRatio={1.99}
                      />
                    </div>

                    <div className="px-4 py-4">
                      <h2 className={`${styles.headingNewsAdvert} mb-2`}>
                        <span
                          className={`text-gray-700 ${styles.headingNewsAdvert}`}
                        >
                          We help our
                        </span>
                        <br />
                        <span
                          className={`text-dimGrey ${styles.headingNewsAdvert}`}
                        >
                          employees thrive
                        </span>
                      </h2>

                      <p className="text-base text-gray-600">
                        Are you a qualified Electrician? Consider joining our
                        collaborative team. We are a leading electrical
                        contractor with highly varied projects in all sectors,
                        offering competitive salaries.
                      </p>
                      <p className="text-base text-gray-600 mt-4">
                        Check back periodically for our open positions, and
                        click here to find details of available roles.
                      </p>
                      
                      <span className="block mt-3 text-sm leading-tight text-quinary font-semibold">
                        0 positions open
                      </span>
                    </div>
                  </div>
                </Link>
              </div> */}

              {/* < !-- divider --> */}
              <div className="border border-dotted"></div>

             

              {/* < !-- divider --> */}
              <div className="border border-dotted"></div>

              {/* < !-- divider --> */}
              <div className="border border-dotted mb-4"></div>
              {/* < !-- Adverts section --> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NewsGrid;
